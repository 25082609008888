<template>
  <div>
    <p class="account-title">Уровни в программе лояльности</p>

    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <card-loyalty-view :lvl="0" :active="active" />
      <card-loyalty-view :lvl="1" :active="active" />
      <card-loyalty-view :lvl="2" :active="active" />
      <card-loyalty-view :lvl="3" :active="active" />
    </b-overlay>

    <faq-view />
  </div>
</template>

<script>
import CardLoyaltyView from "./components/CardLoyalty.vue";
import FaqView from "./components/Faq.vue";

export default {
  data() {
    return {
      is_loading: false,
      active: 2,
    };
  },
  components: {
    CardLoyaltyView,
    FaqView,
  },
};
</script>

<style>
</style>
