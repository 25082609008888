<template>
  <b-card
    no-body
    :class="[
      active == lvl ? 'is-active' : '',
      lvl < active ? 'is-completed' : '',
      `bg-loyalty-lvl-${this.lvl}`,
    ]"
    class="loyalty-card w-100"
    :style="[active == lvl ? 'is-active' : 'border: 2px solid #FFF']"
  >
    <showAt breakpoint="mediumAndBelow">
      <div
        :class="`bg-color-lvl-${this.lvl}`"
        style="
          width: 100%;
          height: 192px;
          border-top-left-radius: 32px;
          border-top-right-radius: 32px;
          padding: 16px 32px;
        "
      >
        <div
          class="bg-avatar-lvl"
          :class="[
            active == lvl ? 'is-active' : '',
            lvl < active ? 'is-completed' : '',
            `bg-loyalty-lvl-${this.lvl}`,
          ]"
        ></div>
      </div>
    </showAt>
    <b-card-body
      class="d-flex flex-column"
      style="padding: 0px"
      v-if="lvl === 0"
    >
      <div style="margin-bottom: auto">
        <b-card-title>
          0 уровень. Новичок, <br />
          <span class="text-muted"
            >кто только зарегистрировались на платформе</span
          >
        </b-card-title>
        <b-card-text>
          <span class="primary-block"
            >Вам доступны стандартные легкие задания, чтобы вы могли быстро
            <br />
            заработать 1000 ₽ и перейти на 1ый уровень.</span
          >
          <b-card-text class="font-weight-bolder" style="margin-top: 8px">
            % от задания: 60%
          </b-card-text>
        </b-card-text>
      </div>
      <div v-if="active == '0'" class="active-lvl">Ваш уровень</div>
      <div v-else-if="lvl < active" class="before-lvl">Пройдено</div>
    </b-card-body>

    <b-card-body
      class="d-flex flex-column"
      style="padding: 0px"
      v-if="lvl === 1"
    >
      <div style="margin-bottom: auto">
        <b-card-title> 1 уровень. Начинающий </b-card-title>
        <b-card-text>
          <span class="primary-block"
            >Доступны более сложные задания с большим заработком.<br />
            Также вы можете оформить платную подписку на
            <b-link to="/">клуб 2work</b-link> <br />
            и <b-link to="/">премиальные предложения </b-link> от
            партнеров.</span
          >
          <b-card-text class="font-weight-bolder" style="margin-top: 8px">
            % от задания: 65%
          </b-card-text>
        </b-card-text>
      </div>
      <div v-if="active == '1'" class="active-lvl">Ваш уровень</div>
      <div v-else-if="lvl < active" class="before-lvl">Пройдено</div>
      <div v-else class="before-lvl">До перехода осталось 1 000 ₽</div>
    </b-card-body>

    <b-card-body
      class="d-flex flex-column"
      style="padding: 0px"
      v-if="lvl === 2"
    >
      <div style="margin-bottom: auto">
        <b-card-title> 2 уровень. Профессионал </b-card-title>
        <b-card-text>
          <span class="primary-block"
            >Вам доступны <b-link to="/">премиальные предложения</b-link>, но
            подписка на клуб<br />
            пока остается платной. Также вы можете
            <b-link to="/">создать свою команду</b-link><br />
            и зарабатывать больше.</span
          >
          <b-card-text class="font-weight-bolder" style="margin-top: 8px">
            % от задания: 70%
          </b-card-text>
        </b-card-text>
      </div>
      <div v-if="active == '2'" class="active-lvl">Ваш уровень</div>
      <div v-else-if="lvl < active" class="before-lvl">Пройдено</div>
      <div v-else class="before-lvl">До перехода осталось 10 000 ₽</div>
    </b-card-body>

    <b-card-body
      class="d-flex flex-column"
      style="padding: 0px"
      v-if="lvl === 3"
    >
      <div style="margin-bottom: auto">
        <b-card-title> 3 уровень. Эксперт </b-card-title>
        <b-card-text>
          <span class="primary-block"
            >Теперь все платные функции платформы для вас бесплатные</span
          >
          <b-card-text class="font-weight-bolder" style="margin-top: 8px">
            % от задания: 75%
          </b-card-text>
        </b-card-text>
      </div>
      <div v-if="active == '3'" class="active-lvl">Ваш уровень</div>
      <div v-else-if="lvl < active" class="before-lvl">Пройдено</div>
      <div v-else class="before-lvl">До перехода осталось 25 000 ₽</div>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    lvl: {
      type: Number,
      default: 0,
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.bg-color-lvl-0 {
  background-color: #f5bf40;
}

.bg-color-lvl-1 {
  background-color: #8581b1;
}

.bg-color-lvl-2 {
  background-color: #534a57;
}

.bg-color-lvl-3 {
  background-color: #4ba5ed;
}

.loyalty-card {
  background-color: transparent;
  padding: 28px 32px;

  @media screen and (max-width: 1128px) {
    padding: 0;
    border: 0;
  }

  .bg-avatar-lvl {
    @media screen and (max-width: 575.98px) {
      margin: 0;
      background-position: center;
    }
  }

  .card-title {
    font-size: 22px;
    margin-bottom: 8px;

    @media screen and (max-width: 575.98px) {
      font-size: 18px;
    }
  }

  .card-text {
    font-size: 16px;
    line-height: 24px;

    @media screen and (max-width: 575.98px) {
      font-size: 14px;
    }
  }

  .card-body {
    @media screen and (max-width: 1128px) {
      padding: 20px 32px 28px 32px !important;
    }

    @media screen and (max-width: 575.98px) {
      padding: 20px !important;
    }
  }

  .primary-block {
    padding-bottom: 6px;
  }

  .active-lvl,
  .before-lvl {
    margin-top: 40px;

    @media screen and (max-width: 1128px) {
      margin-top: 17px;
    }
  }

  .active-lvl {
    width: fit-content;
    padding: 5px 8px 24px;
    gap: 10px;
    height: 28px;
    background: var(--theme-color);
    color: #fff;
    border-radius: 24px;
  }

  .before-lvl {
    width: fit-content;
    padding: 5px 8px 24px;
    gap: 10px;
    height: 28px;
    background: #fff;
    color: var(--theme-color);
    border-radius: 24px;
  }
}

.loyalty-card.is-active {
  background-color: #ffffff;
}

.loyalty-card.is-completed {
  opacity: 0.55;
  cursor: not-allowed !important;
  pointer-events: none;
}
</style>
