<template>
  <div>
    <p data-anime="animate__fadeIn" class="account-title">Ответы на вопросы</p>

    <b-card no-body style="padding: 0 32px">
      <div class="q-section-block bg-white-color faq-block">
        <div class="faq-section animate__animated" data-anime="animate__fadeInUp" data-delay="200">
          <div class="faq-section-header" v-b-toggle.faq-1>
            <p>Что дают уровни рейтинга?</p>
            <feather-icon class="when-open" icon="MinusCircleIcon"
              style="color: var(--link-primary); width: 20px; height: 20px" />
            <feather-icon class="when-closed" icon="PlusCircleIcon"
              style="color: var(--link-primary); width: 20px; height: 20px" />
          </div>

          <b-collapse id="faq-1" class="faq-section-content" role="tabpanel">
            <p>
              Перейдя на определенный уровень рейтинга, вы можете открывать
              функции платформы. На последних уровнях вы получаете их бесплатно,
              а на первом – можете оформить платную подписку.
            </p>
          </b-collapse>
        </div>
        <div class="faq-section animate__animated" data-anime="animate__fadeInUp" data-delay="300">
          <div class="faq-section-header" v-b-toggle.faq-2>
            <p>Как я могу повысить уровень?</p>
            <feather-icon class="when-open" icon="MinusCircleIcon"
              style="color: var(--link-primary); width: 20px; height: 20px" />
            <feather-icon class="when-closed" icon="PlusCircleIcon"
              style="color: var(--link-primary); width: 20px; height: 20px" />
          </div>

          <b-collapse id="faq-2" class="faq-section-content" role="tabpanel">
            <p>Как я могу повысить уровень?</p>
          </b-collapse>
        </div>
        <div class="faq-section animate__animated" data-anime="animate__fadeInUp" data-delay="400">
          <div class="faq-section-header" v-b-toggle.faq-3>
            <p>Что такое % от задания?</p>
            <feather-icon class="when-open" icon="MinusCircleIcon"
              style="color: var(--link-primary); width: 20px; height: 20px" />
            <feather-icon class="when-closed" icon="PlusCircleIcon"
              style="color: var(--link-primary); width: 20px; height: 20px" />
          </div>

          <b-collapse id="faq-3" class="faq-section-content" role="tabpanel">
            <p>Что такое % от задания?</p>
          </b-collapse>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.faq-section {
  &:last-child::after {
    display: none;
  }
}

.faq-section-content {
  p {
    @media screen and (max-width: 530px) {
      max-width: 240px;
    }
  }
}

.faq-section-header {
  p {
    @media screen and (max-width: 530px) {
      max-width: 225px;
    }
  }
}

.collapsed>.when-open,
.not-collapsed>.when-closed {
  display: none;
}
</style>